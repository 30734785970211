import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import categoryApi from 'api/categoryApi';
import { formatQueryParams } from "helpers/format";

export const getCategory = createAsyncThunk("Category" , async (params: any = {}) => {
  try{
    const response = await categoryApi.categories(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getAllCategoriesType = async (params: any = {}) => {
  try{
    const response = await categoryApi.allCategoriesType(params)
    return response;
  }catch (error) {
    return error;
  }
}

export const postCategory = async (data: any = {}) => {
  try{
    const response = await categoryApi.postCategory(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const postCategoryKeywords = async (data: any = {}) => {
  try{
    const response = await categoryApi.postCategoryKeywords(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putCategory = async (id: string | number , data: any = {}) => {
  try{
    const response = await categoryApi.putCategory(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteCategory = async (id: string | number , data: any = {}) => {
  try{
    const response = await categoryApi.deleteCategory(id, data);
    return response;
  }catch (error) {
    return error;
  }
};


export const exportFileCategory = async (params: any = {}) => {
  try{
    const response = await categoryApi.exportFileCategory(params);
    return response;
  }catch (error) {
    return error;
  }
};


