import { createSlice } from "@reduxjs/toolkit";

export interface IState {

  error: any,
};

export const initialState: IState = {

  error: {},
};

const RankifySlice = createSlice({
  name: "Rankify",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

  },
});

export default RankifySlice.reducer;