import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
import { ICategoryStock } from "./types/_stock";
import { IRankifyKeyword } from "./types/_rankify";
const api = new APIClient();

const path = '/rankify-keywords';
const rankifyApi = {
  keywords(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IRankifyKeyword[]>, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },
  allCategories(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<ICategoryStock[]>, any>> {
    const url = `masters/categories`;
    return api.get(url, params);
  },
}
export default rankifyApi
