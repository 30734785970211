import rankifyApi from "api/rankifyApi";

export const getAllCategoriesRankifyNormal = async (
  params: any = { type: "dc_trend" }
) => {
  try {
    const response = await rankifyApi.allCategories(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getRankifyKeywordsByNormal = async (params: any = {}) => {
  try {
    const response = await rankifyApi.keywords(params);
    return response;
  } catch (error) {
    return error;
  }
};