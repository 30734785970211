import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from 'react-select/async';
import { getCarKeywordsByNormal, getCoinKeywordsByNormal, getKeywordsByNormal, getRankifyKeywordsByNormal, getStockKeywordsByNormal, getTrendIndexKeywordsByNormal } from "../../store/thunks";
import { components } from 'react-select';
import moment from "moment";
import ModalCollapseTag from 'components/Common/CollapseTag/ModalCollapseTag';

const MAX_KEYWORD_DEFAULT = 'full'
interface Option {
  label: string;
  value: string;
  isByEnter?: boolean
}
interface Filter {
  page?: number,
  limit?: number,
  category_id?: string | number;
  keyword?: string;
}

interface SearchFilterKeywordProps {
  name?: string,
  initialValue?: Option[] | undefined;
  categoryType?: string,
  filterByCategory?: string,
  isSetListOptionDefaultValueByCategoryId?: boolean,
  disabled?: boolean,
  onChangeSelect?: (params: Option[]) => void;
  isGetListOptionDefault?: boolean,
  maxKeyword?: 'full' | number,
  closeMenuOnSelect?: boolean
}

const SearchFilterKeyword = ({
  name = '',
  initialValue = [],
  filterByCategory = '',
  isSetListOptionDefaultValueByCategoryId = false,
  categoryType = 'default',
  isGetListOptionDefault = true,
  disabled = false,
  onChangeSelect,
  maxKeyword = MAX_KEYWORD_DEFAULT,
  closeMenuOnSelect = false
}: SearchFilterKeywordProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[] | undefined>([]);
  // const [filter, setFilter] = useState<Filter | undefined | null>(filterBy);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const setOnChangeOption = (item: any, option: any = null) => {
    if (option?.removedValue && option?.removedValue?.isFixed) return;
    onChangeSelect && onChangeSelect(item)
  };
  const FILTER_BY = { page: 1, limit: categoryType === 'default' ? 10 : 1200, category_id: filterByCategory, keyword: '' };

  const promiseOptions = (inputValue: string) => {
    return new Promise<Option[]>((resolve) => {
      if (categoryType === 'default') {
        getKeywordsByNormal({ ...FILTER_BY, keyword: inputValue }).then((response: any) => {
          const result = response?.data?.list?.map((item: any) => ({
            label: item?.keyword,
            value: item?.id,
            all: item?.search_monthly,
            pc: item?.search_monthly_pc,
            mobile: item?.search_monthly_mobile,
            categories: item?.categories,
            // visited: true,
            // isFixed: true
          })) || [];
          setOptions((_prev) => result);
          resolve(result);
        });
      } else if (categoryType === 'trend_index') {
        getTrendIndexKeywordsByNormal({ ...FILTER_BY, keyword: inputValue }).then((response: any) => {
          const result = response?.data?.list?.map((item: any) => ({
            label: item?.keyword,
            value: item?.id,
            all: item?.search_monthly,
            categories: item?.categories
          })) || [];
          setOptions((_prev) => result);
          resolve(result);
        });
      } else if (categoryType === 'stock') {
        getStockKeywordsByNormal({ ...FILTER_BY, keyword: inputValue }).then((response: any) => {
          const result = response?.data?.list?.map((item: any) => ({
            label: item?.name,
            value: item?.id,
            all: item?.search_monthly,
            categories: item?.categories
          })) || [];
          setOptions((_prev) => result);
          resolve(result);
        });
      } else if (categoryType === 'car') {
        getCarKeywordsByNormal({ ...FILTER_BY, date_version: moment(new Date()).subtract(1, "months").format("Y-MM"), keyword: inputValue }).then((response: any) => {
          const result = response?.data?.list?.map((item: any) => ({
            label: item?.name,
            value: item?.id,
            all: item?.search_monthly,
            categories: item?.categories
          })) || [];
          setOptions((_prev) => result);
          resolve(result);
        });
      } else if (categoryType === 'coin') {
        getCoinKeywordsByNormal({ ...FILTER_BY, candle_date_time_utc: moment().format("Y-MM-DD"), keyword: inputValue }).then((response: any) => {
          const result = response?.data?.list?.map((item: any) => ({
            label: item?.coin?.korean_name || item?.coin?.english_name || item?.coin?.code,
            value: item?.id,
            all: item?.search_monthly,
            categories: item?.categories
          })) || [];
          setOptions((_prev) => result);
          resolve(result);
        });
      } else if (categoryType === 'dc_trend') {
        getRankifyKeywordsByNormal({ ...FILTER_BY, keyword: inputValue }).then((response: any) => {
          const result = response?.data?.data?.list?.map((item: any) => ({
            label: item?.keyword,
            value: item?.id,
            all: item?.search_monthly,
            categories: item?.categories
          })) || [];
          setOptions((_prev) => result);
          resolve(result);
        });
      }
    });
  };

  const promiseCallKeywordNaver = async (params: Filter) => {
    try {
      const response: any = await getKeywordsByNormal({ ...FILTER_BY, ...params });
      const result = response?.data?.list?.map((item: any) => ({
        label: item?.keyword,
        value: item?.id,
        all: item?.search_monthly,
        pc: item?.search_monthly_pc ?? 0,
        mobile: item?.search_monthly_mobile ?? 0,
        categories: item?.categories,
      })) || [];
      setOptions((_prev) => result);
      // const valueFilterByCategory = initialValue?.filter(() => )
    } catch (error) {
      return error;
    }
  }

  const promiseCallKeywordTrendIndex = async (params: Filter) => {
    try {
      const response: any = await getTrendIndexKeywordsByNormal({ ...FILTER_BY, ...params });
      const result = response?.data?.list?.map((item: any) => ({
        label: item?.keyword,
        value: item?.id,
        all: item?.naver_search_monthly,
        categories: item?.categories,

      })) || [];
      setOptions((_prev) => result);
      setOnChangeOption(result);
      // const valueFilterByCategory = initialValue?.filter(() => )
    } catch (error) {
      return error;
    }
  }

  const promiseCallKeywordStock = async (params: Filter) => {
    try {
      const response: any = await getStockKeywordsByNormal({ ...FILTER_BY, ...params });
      const result = response?.data?.list?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
        all: item?.naver_search_monthly,
        categories: item?.categories,
      })) || [];
      setOptions((_prev) => result);
      setOnChangeOption(result);
      // const valueFilterByCategory = initialValue?.filter(() => )
    } catch (error) {
      return error;
    }
  }

  const promiseCallKeywordCar = async (params: Filter) => {
    try {
      const response: any = await getCarKeywordsByNormal({ ...FILTER_BY, date_version: moment(new Date()).subtract(1, "months").format("Y-MM"), ...params });
      const result = response?.data?.list?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
        all: item?.naver_search_monthly,
        categories: item?.categories,
      })) || [];
      setOptions((_prev) => result);
      setOnChangeOption(result);
      // const valueFilterByCategory = initialValue?.filter(() => )
    } catch (error) {
      return error;
    }
  }

  const promiseCallKeywordCoin = async (params: Filter) => {
    try {
      const response: any = await getCoinKeywordsByNormal({ ...FILTER_BY, candle_date_time_utc: moment().format("Y-MM-DD"), ...params });
      const result = response?.data?.list?.map((item: any) => ({
        label: item?.coin?.korean_name || item?.coin?.english_name || item?.coin?.code,
        value: item?.id,
        all: item?.naver_search_monthly,
        categories: item?.categories,
      })) || [];
      setOptions((_prev) => result);
      setOnChangeOption(result);
      // const valueFilterByCategory = initialValue?.filter(() => )
    } catch (error) {
      return error;
    }
  }


  const promiseCallKeywordRankify = async (params: Filter) => {
    try {
      const response: any = await getRankifyKeywordsByNormal({ ...FILTER_BY, ...params });
      const result = response?.data?.data?.list?.map((item: any) => ({
        label: item?.keyword,
        value: item?.id,
        all: item?.naver_search_monthly,
        categories: item?.categories,
      })) || [];
      setOptions((_prev) => result);
      setOnChangeOption(result);
      // const valueFilterByCategory = initialValue?.filter(() => )
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    if (isGetListOptionDefault || filterByCategory) {
      if (categoryType === 'default') {
        promiseCallKeywordNaver({ category_id: filterByCategory || '' });
      } else if (categoryType === 'trend_index') {
        promiseCallKeywordTrendIndex({ category_id: filterByCategory || '' });
      } else if (categoryType === 'stock') {
        promiseCallKeywordStock({ category_id: filterByCategory || '' });
      } else if (categoryType === 'car') {
        promiseCallKeywordCar({ category_id: filterByCategory || '' });
      } else if (categoryType === 'coin') {
        promiseCallKeywordCoin({ category_id: filterByCategory || '' });
      } else if (categoryType === 'dc_trend') {
        promiseCallKeywordRankify({ category_id: filterByCategory || '' });
      }

    }
  }, [filterByCategory, categoryType, isGetListOptionDefault]);

  const toggleModalCollapseKeyword = () => {
    setIsOpenModal((_prev) => !_prev);
  }

  const ValueContainer = ({ children, ...props }: any) => {
    let [values, input] = children;

    if (typeof maxKeyword === 'number' && maxKeyword < initialValue.length) {

      const keywordimit = values.slice(0, maxKeyword);

      return (
        <components.ValueContainer {...props}>
          {keywordimit}
          <div className={`cursor-pointer d-inline-block link-primary d-contents fs-12 ms-2 pe-auto btn-showmore-search-filter-keyword`}>
            <div className="d-flex align-items-center" onClick={toggleModalCollapseKeyword}>
              <span>{t('More')}</span>
              <i className="ri-arrow-down-s-line fs-16" />
            </div>
          </div>
          {input}
        </components.ValueContainer>
      );
    }
    else {
      return (
        <components.ValueContainer {...props}>
          {values}
          {input}
        </components.ValueContainer>
      )
    }
  };

  const getDataKeyWordModal = () => {
    const tags = initialValue.map((value) => {
      const { label } = value
      return label
    })
    return tags;
  }

  return (
    <React.Fragment>
      <AsyncSelect
        cacheOptions
        defaultOptions={options}
        isMulti
        isDisabled={disabled}
        closeMenuOnSelect={closeMenuOnSelect}
        name={name}
        value={initialValue}
        loadOptions={promiseOptions}
        isClearable={!options?.some((client: any) => client?.isFixed)}
        onChange={(e: any, op: any) => setOnChangeOption(e, op)}
        placeholder={<div>{t('Select keyword')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (
          <div className="d-flex">{option?.label}
            <div className="op ms-2">
              {`${option?.all ? 'T' + option?.all : ''}`} {`${option?.pc ? ' / P' + option?.pc : ''}`}  {`${option?.mobile ? ' / M' + option?.mobile : ''}`}
            </div>
          </div>)}
        className="search-filter-keyword"
        classNamePrefix="name-prefix"
        styles={{
          multiValue: (base: any, state: any) => {
            return state?.data?.isFixed ? { ...base, backgroundColor: 'var(--vz-footer-color)' } : (state?.data?.isByEnter ? { ...base, backgroundColor: 'var(--vz-light)' } : base);
          },
          multiValueLabel: (base: any, state: any) => {
            return state?.data?.isFixed
              ? { ...base, color: "white", paddingRight: 6 }
              : base;
          },
          multiValueRemove: (base: any, state: any) => {
            return state?.data?.isFixed ? { ...base, display: "none" } : base;
          },
        }}
        components={{ ValueContainer }}
      />
      <ModalCollapseTag isOpen={isOpenModal}
        scrollable={true}
        onCloseClick={toggleModalCollapseKeyword}
        keyboard={true}
        tags={getDataKeyWordModal()} />
    </React.Fragment>
  );
};

export default SearchFilterKeyword;
